// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchPreloadedSearchLocationsIndexCitiesRequest from "./requests/FetchPreloadedSearchLocationsIndexCitiesRequest.res.js";
import * as FetchPreloadedSearchLocationsIndexProvidersRequest from "./requests/FetchPreloadedSearchLocationsIndexProvidersRequest.res.js";

var Index = {
  providers: FetchPreloadedSearchLocationsIndexProvidersRequest.exec,
  cities: FetchPreloadedSearchLocationsIndexCitiesRequest.exec
};

export {
  Index ,
}
/* FetchPreloadedSearchLocationsIndexCitiesRequest Not a pure module */
