// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PreloadedSearchLocation from "../../../models/PreloadedSearchLocation.res.js";

function exec(query, countryIdOpt, stateIdOpt) {
  var countryId = countryIdOpt !== undefined ? Caml_option.valFromOption(countryIdOpt) : undefined;
  var stateId = stateIdOpt !== undefined ? Caml_option.valFromOption(stateIdOpt) : undefined;
  var query$1 = PreloadedSearchLocation.Index.City.Index.query(query);
  var countryId$1 = countryId !== undefined ? "&country_id=" + ID.toString(Caml_option.valFromOption(countryId)) : "";
  var stateId$1 = stateId !== undefined ? "&state_id=" + ID.toString(Caml_option.valFromOption(stateId)) : "";
  return Rest.$$fetch("/preloaded_search_locations/cities?" + query$1 + countryId$1 + stateId$1, "Get", {
              NAME: "Json",
              VAL: PreloadedSearchLocation.Index.City.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
