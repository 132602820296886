// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexContentCss from "./LocationsIndexContentCss.res.js";
import * as LocationsIndexContentAbout from "./components/LocationsIndexContentAbout.res.js";

function LocationsIndexContent(props) {
  var __market = props.market;
  var market = __market !== undefined ? __market : "Global";
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(LocationsIndexContentAbout.make, {
                    market: market,
                    totalLocations: props.totalLocations,
                    totalProviders: props.totalProviders,
                    totalCountries: props.totalCountries,
                    totalCities: props.totalCities,
                    topProviders: props.topProviders,
                    content: props.content
                  }),
              className: LocationsIndexContentCss.locationsContentContainer
            });
}

var Css;

var make = LocationsIndexContent;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
