// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../libs/ID.res.js";
import * as Badge from "../../../../../styleguide/components/Badge/Badge.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchField from "../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as ErrorMessage from "../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as React$1 from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexFiltersCss from "./LocationsIndexFiltersCss.res.js";
import * as Solid from "@heroicons/react/16/solid";
import * as Solid$1 from "@heroicons/react/24/solid";
import * as Outline from "@heroicons/react/24/outline";
import * as Api_PreloadedSearchLocation from "../../../../../api/preloaded-search-locations/Api_PreloadedSearchLocation.res.js";
import * as LocationsIndexFiltersSection from "./components/LocationsIndexFiltersSection.res.js";

var initialState = {
  providers: true,
  city: true
};

function reducer(state, action) {
  if (action === "ToggleProviders") {
    return {
            providers: !state.providers,
            city: state.city
          };
  } else {
    return {
            providers: state.providers,
            city: !state.city
          };
  }
}

var Expand = {
  initialState: initialState,
  reducer: reducer
};

var initialState_providersStatus = {
  TAG: "ReadyProviders",
  _0: {
    providers: []
  }
};

var initialState_providers = [];

var initialState_providerIds = [];

var initialState_selectedProviders = [];

var initialState_citiesStatus = {
  TAG: "ReadyCities",
  _0: {
    cities: []
  }
};

var initialState_cities = [];

var initialState_radius = 100;

var initialState$1 = {
  providersStatus: initialState_providersStatus,
  providers: initialState_providers,
  providerIds: initialState_providerIds,
  providersSearch: "",
  selectedProviders: initialState_selectedProviders,
  providersCount: 0,
  citiesStatus: initialState_citiesStatus,
  citiesSearch: "",
  city: undefined,
  cities: initialState_cities,
  radius: initialState_radius
};

var Filters = {
  initialState: initialState$1
};

function LocationsIndexFilters(props) {
  var onClick = props.onClick;
  var __showCityFilter = props.showCityFilter;
  var stateId = props.stateId;
  var countryId = props.countryId;
  var updateCityRadius = props.updateCityRadius;
  var updateProviderIds = props.updateProviderIds;
  var enableReset = props.enableReset;
  var resetCounter = props.resetCounter;
  var filtersOpen = props.filtersOpen;
  var showCityFilter = __showCityFilter !== undefined ? __showCityFilter : true;
  var searchProviders = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformProvidersSearch",
                _0: param.state.providersSearch
              });
        }));
  var searchCities = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformCitiesSearch",
                _0: param.state.citiesSearch
              });
        }));
  var match = React.useReducer(reducer, initialState);
  var expandDispatch = match[1];
  var expandState = match[0];
  var match$1 = Hooks.useReducer(initialState$1, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: state.selectedProviders,
                            providersCount: 0,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: state.cities,
                            radius: state.radius
                          }
                        };
              case "ResetProviderIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: [],
                            providersSearch: "",
                            selectedProviders: [],
                            providersCount: 0,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: state.cities,
                            radius: state.radius
                          },
                          _1: updateProviderIds([])
                        };
              case "ResetCityRadius" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: "",
                            city: undefined,
                            cities: state.cities,
                            radius: 100
                          },
                          _1: updateCityRadius(undefined, 100)
                        };
              case "FetchProviders" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_PreloadedSearchLocation.Index.providers(state.providersSearch), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProvidersFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "LocationsIndexFilters",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "LocationsIndexFilters.make"
                                          }, "FailProvidersFetch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProvidersFetch");
                                    }));
                            })
                        };
              case "FailProvidersFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            providersStatus: "FailedProvidersFetch",
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: state.cities,
                            radius: state.radius
                          }
                        };
              case "FetchCities" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_PreloadedSearchLocation.Index.cities(state.citiesSearch, Caml_option.some(countryId), Caml_option.some(stateId)), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedCitiesFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "LocationsIndexFilters",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "LocationsIndexFilters.make"
                                          }, "FailCitiesFetch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailCitiesFetch");
                                    }));
                            })
                        };
              case "FailCitiesFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: "FailedCitiesFetch",
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: state.cities,
                            radius: state.radius
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateProviderIds" :
                  var providerId = action._0;
                  var providerIds = state.providerIds.includes(providerId) ? (function (__x) {
                          return __x.filter(function (id) {
                                      return Caml_obj.notequal(id, providerId);
                                    });
                        })(state.providerIds) : state.providerIds.concat([providerId]);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: providerIds,
                            providersSearch: "",
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: state.cities,
                            radius: state.radius
                          },
                          _1: updateProviderIds(providerIds)
                        };
              case "SucceedProvidersFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            providersStatus: {
                              TAG: "ReadyProviders",
                              _0: res
                            },
                            providers: res.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: state.cities,
                            radius: state.radius
                          }
                        };
              case "UpdateProvidersSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: action._0,
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: state.cities,
                            radius: state.radius
                          },
                          _1: searchProviders
                        };
              case "PerformProvidersSearch" :
                  var match = state.providersStatus;
                  if (typeof match !== "object" && match === "FetchingProviders") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.providersSearch) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              providersStatus: "FetchingProviders",
                              providers: state.providers,
                              providerIds: state.providerIds,
                              providersSearch: state.providersSearch,
                              selectedProviders: state.selectedProviders,
                              providersCount: state.providersCount,
                              citiesStatus: state.citiesStatus,
                              citiesSearch: state.citiesSearch,
                              city: state.city,
                              cities: state.cities,
                              radius: state.radius
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviders");
                              })
                          };
                  }
              case "UpdateSelectedProviders" :
                  var provider = action._0;
                  var providers = state.selectedProviders.includes(provider) ? state.selectedProviders.filter(function (prov) {
                          return Caml_obj.notequal(prov.id, provider.id);
                        }) : state.selectedProviders.concat([provider]);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: providers,
                            providersCount: providers.length,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: state.cities,
                            radius: state.radius
                          },
                          _1: (function (param) {
                              param.dispatch({
                                    TAG: "UpdateProviderIds",
                                    _0: provider.id
                                  });
                            })
                        };
              case "SucceedCitiesFetch" :
                  var res$1 = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: {
                              TAG: "ReadyCities",
                              _0: res$1
                            },
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: res$1.cities,
                            radius: state.radius
                          }
                        };
              case "UpdateCitiesSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: action._0,
                            city: state.city,
                            cities: state.cities,
                            radius: state.radius
                          },
                          _1: searchCities
                        };
              case "PerformCitiesSearch" :
                  var match$1 = state.citiesStatus;
                  if (typeof match$1 !== "object" && match$1 === "FetchingCities") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.citiesSearch) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              providersStatus: state.providersStatus,
                              providers: state.providers,
                              providerIds: state.providerIds,
                              providersSearch: state.providersSearch,
                              selectedProviders: state.selectedProviders,
                              providersCount: state.providersCount,
                              citiesStatus: "FetchingCities",
                              citiesSearch: state.citiesSearch,
                              city: state.city,
                              cities: state.cities,
                              radius: state.radius
                            },
                            _1: (function (param) {
                                param.dispatch("FetchCities");
                              })
                          };
                  }
              case "UpdateSelectedCity" :
                  var city = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: "",
                            city: city,
                            cities: state.cities,
                            radius: state.radius
                          },
                          _1: updateCityRadius(city !== undefined ? Caml_option.some(city.id) : undefined, state.radius)
                        };
              case "UpdateCityRadius" :
                  var radius = action._0;
                  var x = state.city;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providersStatus: state.providersStatus,
                            providers: state.providers,
                            providerIds: state.providerIds,
                            providersSearch: state.providersSearch,
                            selectedProviders: state.selectedProviders,
                            providersCount: state.providersCount,
                            citiesStatus: state.citiesStatus,
                            citiesSearch: state.citiesSearch,
                            city: state.city,
                            cities: state.cities,
                            radius: radius
                          },
                          _1: updateCityRadius(x !== undefined ? Caml_option.some(x.id) : undefined, radius)
                        };
              
            }
          }
        }));
  var filtersDispatch = match$1[1];
  var filtersState = match$1[0];
  React.useEffect((function () {
          if (resetCounter > 0) {
            filtersDispatch("Reset");
            filtersDispatch("ResetProviderIds");
            filtersDispatch("ResetCityRadius");
          }
          
        }), [resetCounter]);
  var match$2 = filtersState.providersStatus;
  var tmp;
  var exit = 0;
  if (typeof match$2 !== "object" && match$2 !== "FetchingProviders") {
    tmp = JsxRuntime.jsx(ErrorMessage.make, {
          children: "Something went wrong."
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var tmp$1;
    if (filtersState.providers.length !== 0) {
      tmp$1 = Belt_Array.map(filtersState.providers, (function (provider) {
              var id = "locations-providers-search-filter--provider-" + ID.toString(provider.id);
              return JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("span", {
                                      children: provider.name,
                                      className: LocationsIndexFiltersCss.ownerName
                                    }),
                                className: LocationsIndexFiltersCss.ownerNameContainer
                              }),
                          className: LocationsIndexFiltersCss.searchItem,
                          onClick: (function (param) {
                              enableReset();
                              filtersDispatch({
                                    TAG: "UpdateSelectedProviders",
                                    _0: provider
                                  });
                            })
                        }, id);
            }));
    } else {
      var match$3 = filtersState.providersSearch;
      var tmp$2 = match$3 === "" ? "Search and select a provider." : "No providers found.";
      tmp$1 = JsxRuntime.jsx("div", {
            children: tmp$2,
            className: LocationsIndexFiltersCss.searchItem
          }, "no-providers");
    }
    tmp = JsxRuntime.jsxs(Popover.make, {
          className: LocationsIndexFiltersCss.searchContainer,
          children: [
            JsxRuntime.jsx(Popover.Trigger.make, {
                  children: JsxRuntime.jsx(SearchField.make, {
                        id: "providers-search",
                        value: filtersState.providersSearch,
                        placeholder: "Search Providers",
                        inputClassName: "",
                        onChange: (function ($$event) {
                            filtersDispatch({
                                  TAG: "UpdateProvidersSearchInput",
                                  _0: $$event.target.value
                                });
                          }),
                        showSearchIcon: false
                      })
                }),
            JsxRuntime.jsx(Popover.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: LocationsIndexFiltersCss.searchBody,
                  children: tmp$1
                })
          ]
        });
  }
  var tmp$3;
  if (showCityFilter) {
    var match$4 = filtersState.providersStatus;
    var tmp$4;
    var exit$1 = 0;
    if (typeof match$4 !== "object" && match$4 !== "FetchingProviders") {
      tmp$4 = JsxRuntime.jsx(ErrorMessage.make, {
            children: "Something went wrong."
          });
    } else {
      exit$1 = 1;
    }
    if (exit$1 === 1) {
      var x = filtersState.city;
      var tmp$5;
      if (filtersState.cities.length !== 0) {
        tmp$5 = Belt_Array.map(filtersState.cities, (function (city) {
                var id = "locations-cities-search-filter--city-" + ID.toString(city.id);
                return JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx("span", {
                                        children: city.name + ", " + city.stateName + ", " + city.countryName,
                                        className: LocationsIndexFiltersCss.ownerName
                                      }),
                                  className: LocationsIndexFiltersCss.ownerNameContainer
                                }),
                            className: LocationsIndexFiltersCss.searchItem,
                            onClick: (function (param) {
                                enableReset();
                                filtersDispatch({
                                      TAG: "UpdateSelectedCity",
                                      _0: city
                                    });
                              })
                          }, id);
              }));
      } else {
        var match$5 = filtersState.citiesSearch;
        var tmp$6 = match$5 === "" ? "Search and select a city." : "No cities found.";
        tmp$5 = JsxRuntime.jsx("div", {
              children: tmp$6,
              className: LocationsIndexFiltersCss.searchItem
            }, "no-cities");
      }
      tmp$4 = JsxRuntime.jsxs(Popover.make, {
            id: "cityName",
            className: LocationsIndexFiltersCss.searchContainer,
            children: [
              JsxRuntime.jsxs(Popover.Trigger.make, {
                    className: "relative",
                    children: [
                      JsxRuntime.jsx(SearchField.make, {
                            id: "cities-search",
                            value: filtersState.citiesSearch,
                            placeholder: x !== undefined ? x.name + ", " + x.stateName + ", " + x.countryName : "Search Cities",
                            inputClassName: "",
                            onChange: (function ($$event) {
                                filtersDispatch({
                                      TAG: "UpdateCitiesSearchInput",
                                      _0: $$event.target.value
                                    });
                              }),
                            showSearchIcon: false,
                            disabled: filtersState.city !== undefined
                          }),
                      filtersState.city === undefined ? null : JsxRuntime.jsx(Solid$1.XCircleIcon, {
                              className: LocationsIndexFiltersCss.Clear.icon,
                              onClick: (function () {
                                  filtersDispatch({
                                        TAG: "UpdateSelectedCity",
                                        _0: undefined
                                      });
                                })
                            })
                    ]
                  }),
              JsxRuntime.jsx(Popover.Body.make, {
                    position: {
                      TAG: "Below",
                      _0: "LeftEdge"
                    },
                    className: LocationsIndexFiltersCss.searchBody,
                    children: tmp$5
                  })
            ]
          });
    }
    tmp$3 = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(Label.make, {
                  forId: "cityName",
                  className: LocationsIndexFiltersCss.Label.container,
                  children: "Find a City"
                }),
            tmp$4
          ],
          className: LocationsIndexFiltersCss.Field.container
        });
  } else {
    tmp$3 = null;
  }
  var x$1 = filtersState.radius;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(React$1.Transition, {
                      show: !filtersOpen,
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Outline.AdjustmentsHorizontalIcon, {
                                    className: LocationsIndexFiltersCss.Icon.icon
                                  }),
                              JsxRuntime.jsx(Solid$1.BuildingOfficeIcon, {
                                    className: LocationsIndexFiltersCss.Icon.icon
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: String(filtersState.providersCount),
                                    className: LocationsIndexFiltersCss.Icon.filtersCount
                                  })
                            ],
                            className: LocationsIndexFiltersCss.Icon.container,
                            onClick: onClick
                          })
                    }),
                JsxRuntime.jsx(React$1.Transition, {
                      show: filtersOpen,
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: "Data Center Filters"
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx(Outline.AdjustmentsHorizontalIcon, {
                                                          className: LocationsIndexFiltersCss.Icon.icon
                                                        }),
                                                    onClick: onClick
                                                  })
                                            ],
                                            className: LocationsIndexFiltersCss.title
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Select from the filters below to narrow the list of data center locations.",
                                            className: LocationsIndexFiltersCss.description
                                          })
                                    ],
                                    className: LocationsIndexFiltersCss.header
                                  }),
                              JsxRuntime.jsxs(LocationsIndexFiltersSection.make, {
                                    title: "Providers",
                                    isExpanded: expandState.providers,
                                    onClick: (function (param) {
                                        expandDispatch("ToggleProviders");
                                      }),
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: tmp
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: Belt_Array.mapWithIndex(filtersState.selectedProviders, (function (index, provider) {
                                                    return JsxRuntime.jsxs(Badge.Span.make, {
                                                                color: "Gray",
                                                                size: "XS",
                                                                className: LocationsIndexFiltersCss.selectedOwner,
                                                                onClick: (function (param) {
                                                                    filtersDispatch({
                                                                          TAG: "UpdateSelectedProviders",
                                                                          _0: provider
                                                                        });
                                                                  }),
                                                                children: [
                                                                  JsxRuntime.jsx("span", {
                                                                        children: provider.name
                                                                      }),
                                                                  JsxRuntime.jsx(Solid.XMarkIcon, {
                                                                        className: "size-4"
                                                                      })
                                                                ]
                                                              }, "selected-provider-" + ID.toString(provider.id) + "-" + String(index));
                                                  })),
                                            className: LocationsIndexFiltersCss.selectedOwners
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(LocationsIndexFiltersSection.make, {
                                    title: "City",
                                    isExpanded: expandState.city,
                                    onClick: (function (param) {
                                        expandDispatch("ToggleCity");
                                      }),
                                    children: [
                                      tmp$3,
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "radius",
                                                    className: LocationsIndexFiltersCss.Label.container,
                                                    children: "Radius (mi)"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "radius",
                                                    value: x$1 !== undefined ? String(x$1) : "",
                                                    placeholder: "Set a radius. Default 100 mi.",
                                                    onChange: (function ($$event) {
                                                        var value = $$event.target.value;
                                                        filtersDispatch({
                                                              TAG: "UpdateCityRadius",
                                                              _0: value === "" ? undefined : value
                                                            });
                                                        enableReset();
                                                      })
                                                  })
                                            ],
                                            className: LocationsIndexFiltersCss.Field.container
                                          })
                                    ]
                                  })
                            ],
                            className: LocationsIndexFiltersCss.container
                          })
                    })
              ]
            });
}

var Css;

var Filter;

var FilterCheckbox;

var make = LocationsIndexFilters;

export {
  Css ,
  Filter ,
  FilterCheckbox ,
  Expand ,
  Filters ,
  make ,
}
/* ID Not a pure module */
