// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexFiltersCss from "../LocationsIndexFiltersCss.res.js";
import * as Outline from "@heroicons/react/24/outline";

function LocationsIndexFiltersSection(props) {
  var isExpanded = props.isExpanded;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: props.title,
                              className: LocationsIndexFiltersCss.Section.title
                            }),
                        JsxRuntime.jsx("a", {
                              children: JsxRuntime.jsx("div", {
                                    children: isExpanded ? JsxRuntime.jsx(Outline.ChevronUpIcon, {
                                            className: LocationsIndexFiltersCss.Section.chevron
                                          }) : JsxRuntime.jsx(Outline.ChevronDownIcon, {
                                            className: LocationsIndexFiltersCss.Section.chevron
                                          })
                                  }),
                              onClick: props.onClick
                            })
                      ],
                      className: LocationsIndexFiltersCss.Section.titleContainer
                    }),
                isExpanded ? props.children : null
              ],
              className: LocationsIndexFiltersCss.Section.container
            });
}

var Css;

var make = LocationsIndexFiltersSection;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
