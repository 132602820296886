// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as PreloadedSearchLocation from "../../../models/PreloadedSearchLocation.res.js";

function exec(query) {
  var query$1 = PreloadedSearchLocation.Index.Provider.Index.query(query);
  return Rest.$$fetch("/preloaded_search_locations/providers?" + query$1, "Get", {
              NAME: "Json",
              VAL: PreloadedSearchLocation.Index.Provider.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
