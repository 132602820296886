// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexBookACall from "../../book-a-call/components/LocationsIndexBookACall.res.js";
import * as LocationsIndexContentCss from "../LocationsIndexContentCss.res.js";

function LocationsIndexSidebarCallout(props) {
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: props.title,
                      className: LocationsIndexContentCss.About.Callout.title
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "MD",
                      color: "Primary",
                      expanded: true,
                      className: LocationsIndexContentCss.About.Callout.button,
                      onClick: (function (param) {
                          setModalOpen(function (param) {
                                return true;
                              });
                        }),
                      children: props.buttonTitle
                    }),
                match[0] ? JsxRuntime.jsx(LocationsIndexBookACall.make, {
                        onClose: (function () {
                            setModalOpen(function (param) {
                                  return false;
                                });
                          })
                      }) : null
              ],
              className: LocationsIndexContentCss.About.Callout.container
            });
}

var Css;

var make = LocationsIndexSidebarCallout;

export {
  Css ,
  make ,
}
/* react Not a pure module */
