// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Country from "../../../../../routes/common/Routes_Country.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexTopListCss from "../styles/LocationsIndexTopListCss.res.js";

function LocationsIndexTopProvidersNew(props) {
  var providers = props.providers;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Top " + String(providers.length) + " Providers with Data Centers",
                      className: LocationsIndexTopListCss.title
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        Belt_Array.mapWithIndex(providers, (function (index, provider) {
                                return JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("a", {
                                                    children: LocationsIndexTopListCss.truncateName(provider.name),
                                                    className: LocationsIndexTopListCss.TopList.Item.name,
                                                    href: Routes_Country.show(provider.slug)
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: String(provider.locationsCountActive),
                                                    className: LocationsIndexTopListCss.TopList.Item.totalLocations
                                                  })
                                            ],
                                            className: LocationsIndexTopListCss.TopList.Item.container
                                          }, String(index));
                              })),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("a", {
                                    children: "View All Providers",
                                    className: LocationsIndexTopListCss.TopList.Item.link,
                                    href: Routes_Provider.index
                                  }),
                              className: LocationsIndexTopListCss.TopList.Item.container
                            })
                      ],
                      className: LocationsIndexTopListCss.TopList.container
                    })
              ],
              className: LocationsIndexTopListCss.container
            });
}

var Css;

var make = LocationsIndexTopProvidersNew;

export {
  Css ,
  make ,
}
/* Routes_Country Not a pure module */
